
import { defineComponent, onMounted, ref } from "vue";
import verify from "@/components/Verify.vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

export default defineComponent({
  name: "verification",
  components: { verify },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const url = ref(route.query.verify_url);
    const data = ref();
    onMounted(() => {
      if (!url.value) {
        router.push({ name: "404" });
      } else {
        checkVerification();
      }

      function checkVerification() {
        axios
          .get(`${url.value}`)
          .then((rest) => {
            if (rest?.data?.message) {
              data.value = rest.data.message;
            }
          })
          .catch(function () {
            router.push({ name: "404" });
          });
      }
    });
    return {data};
  },
});
