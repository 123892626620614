import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_verify = _resolveComponent("verify")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.data)
      ? (_openBlock(), _createBlock(_component_verify, {
          key: 0,
          data: _ctx.data
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}